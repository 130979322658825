module.exports = [
  {
    value: "1",
    label: "1",
    previousValue: null,
  },
  {
    value: "2",
    label: "2",
    previousValue: "1",
  },
  {
    value: "3",
    label: "3",
    previousValue: "2",
  },
  {
    value: "4",
    label: "4",
    previousValue: "3",
  },
  {
    value: "5",
    label: "5",
    previousValue: "4",
  },
  {
    value: "6",
    label: "6",
    previousValue: "5",
  },
  {
    value: "7",
    label: "7",
    previousValue: "6",
  },
  {
    value: "8",
    label: "8",
    previousValue: "7",
  },
  {
    value: "9",
    label: "9",
    previousValue: "8",
  },
  {
    value: "Maintenance-1",
    label: "Maintenance-1",
    previousValue: "9",
  },
  {
    value: "Maintenance-2",
    label: "Maintenance-2",
    previousValue: "Maintenance-1",
  },
];
