import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "pages/home/HomePage";
import LogoutPage from "pages/authentication/logout-page/LogoutPage";
import PatientDatabase from "pages/patient/patient-dataBase/patient-dataBase";
import Staff from "pages/staff-management/staff/staff";
import Appointments from "pages/appointments/appointments";
import ContentManagement from "pages/content-management/ContentManagement";
import NoRouteFound from "pages/errors/LoggedIn/404";

const AdminUserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/patient-database" component={PatientDatabase} />
      <Route exact path="/content-management" component={ContentManagement} />
      <Route exact path="/staff-management" component={Staff} />
      <Route exact path="/appointments" component={Appointments} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

AdminUserRoutes.propTypes = {};
export default AdminUserRoutes;
