import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import ContentsTable from "components/content/ContentsTable/ContentsTable";
import Options from "components/content/ContentsTable/Options/TableOptions";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import UploadContent from "components/forms/content-management/upload-content/UploadContent";
import Loader from "components/core/loaders/circular/Circular";
import AlertModal from "components/core/modal/alert-modal/AlertModal";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import contentService from "services/content/content-service";
import SESSION_NUMBERS from "lib/const/appoitmentSessions";
import stylesConfig from "theme/config";
import styles from "./ContentManagement.styles";

const filterButtons = [
  {
    id: "live",
    name: "Live",
    active: true,
  },
  {
    id: "archived",
    name: "Archived",
    active: false,
  },
];

const tableFormat = [
  { id: "title", label: "Title", allowSort: true },
  { id: "dateCreated", label: "Date Added", allowSort: true },
  { id: "session", label: "Session", allowSort: false },
  { id: "file", label: "File", allowSort: false },
];

const MODAL_DATA = {
  create: {
    title: "Content Added",
    description:
      "Your content has been successfully added. You can edit or archive it from the content management page.",
  },
  archived: {
    title: "Content Archived",
    description:
      "The content has been successfully archived. It will no longer appear in the app but will still be available to you via the archive tab in the content management section off the portal.",
  },
  unArchived: {
    title: "Content Un-Archived",
    description:
      "The content has been successfully un-archived. It will appear in the app and will be available to you via the live tab in the content management section off the portal.",
  },
  edited: {
    title: "Content Edited",
    description:
      "Your content has been successfully updated. You can edit or archive it from the content management page.",
  },
  deleted: {
    title: "Content Deleted",
    description: "Your content has been successfully deleted.",
  },
};

const ROWS_PER_PAGE = 10;

const ContentManagement = () => {
  const [content, updateContent] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [isAlertOpen, updateIsAlertOpen] = useState(false);
  const [isModalClosed, updateCloseModal] = useState(false);
  const [alertType, updateAlertType] = useState("create");
  const [filter, updateFilter] = useState({
    stage: "all",
    title: "",
    isArchived: false,
    buttons: filterButtons,
  });
  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no content."
  );
  // Pagination state
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  // Sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");

  const closeModal = () => updateCloseModal(true);

  const getContent = useCallback(
    async (nextPage, sOrder = order, sOrderBy = orderBy, search = filter) => {
      const pageToSearch = nextPage || 0;
      const stageToSearch = search.stage === "all" ? null : search.stage;

      const sSearch = {
        ...search,
        name: search.title,
        session: stageToSearch,
      };

      const response = await contentService.getContents(
        pageToSearch,
        rowsPerPage,
        sSearch,
        sOrder,
        sOrderBy
      );

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      setPage(pageToSearch);
      setTotal(response.total);
      setRowsPerPage(ROWS_PER_PAGE);

      const contentsArray = response.results.map((content) => {
        return {
          id: content.id,
          firstName: content.title,
          isArchived: content.isArchived,
          fields: [
            content.title,
            moment(content.dateCreated).format("DD/MM/YYYY"),
            content.sessionNumber,
            content.fileName,
          ],
        };
      });

      updateContent(contentsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getContent(page);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    await getContent(page, newOrder, property);
  };

  const handleFilterButtonSelection = async (id) => {
    const clonedState = [...filter.buttons];
    let newState = null;

    const updatedButtons = clonedState.map((item) => {
      const isActive = item.id === id;

      if (isActive) {
        if (item.id === "live") {
          newState = false;
        } else if (item.id === "archived") {
          newState = true;
        }
      }

      return {
        ...item,
        active: isActive,
      };
    });

    const newFilter = {
      ...filter,
      isArchived: newState,
      buttons: updatedButtons,
      key: Math.random(),
    };

    updateFilter(newFilter);

    const newOrder = "asc";
    const newOrderBy = "title";

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);

    getContent(0, newOrder, newOrderBy, newFilter);
  };

  const handleFilterChange = (e) => {
    const newOrder = "asc";
    const newOrderBy = "title";

    const newFilter = {
      ...filter,
      key: Math.random(),
      [e.target.id]: e.target.value,
    };

    updateFilter(newFilter);

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getContent(0, newOrder, newOrderBy, newFilter);
  };

  const handleAddContent = (alertType) => {
    const newOrder = "asc";
    const newOrderBy = "title";

    updateFilter({
      stage: "all",
      title: "",
      isArchived: false,
      key: "",
      buttons: filterButtons,
    });

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getContent(0, newOrder, newOrderBy);
  };

  const openAlertModal = () => updateIsAlertOpen(true);

  const closeAlertModal = () => updateIsAlertOpen(!isAlertOpen);

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  return (
    <>
      <LoggedIn
        smallHeading="Session Content"
        buttonModal={{
          title: "Upload Content",
          modalContent: (
            <UploadContent
              closeModal={closeModal}
              onComplete={() => {
                updateAlertType("create");
                openAlertModal();
                handleAddContent();
              }}
            />
          ),
          isModalClosed: isModalClosed,
        }}
      >
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box style={styles.tableFilter}>
                  <div style={styles.container}>
                    {filter.buttons.map((item) => {
                      let isFilterSelectedStyles = {};
                      if (item.active) {
                        isFilterSelectedStyles = styles.bold;
                      }

                      return (
                        <div id={item.id} style={styles.filterButtonContainer}>
                          <PrimaryButton
                            sx={{
                              ...styles.filterButton,
                              ...isFilterSelectedStyles,
                            }}
                            text={item.name}
                            naked
                            onClick={() => handleFilterButtonSelection(item.id)}
                          />
                          {item.active && (
                            <div style={styles.filterButtonUnderLine} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div style={styles.filterSearchContainer}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        id="title"
                        label="Search"
                        variant="standard"
                        value={filter.title}
                        placeholder="start typing"
                        focused
                        onChange={handleFilterChange}
                        sx={{
                          minWidth: 256,
                          paddingRight: stylesConfig.spacing.veryLarge,
                        }}
                      />

                      <Box>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            sx={styles.filterInputLable}
                          >
                            Stage
                          </InputLabel>
                          <NativeSelect
                            sx={styles.fitlerDropbox}
                            value={filter.stage}
                            onChange={handleFilterChange}
                            inputProps={{
                              name: "stage",
                              id: "stage",
                            }}
                          >
                            <option value="all">All</option>
                            {SESSION_NUMBERS.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </div>

                    <PrimaryButton
                      text="Clear Filter"
                      theme="outline"
                      sx={styles.filterButton}
                      onClick={handleAddContent}
                    />
                  </div>
                </Box>
                <ContentsTable
                  page={page}
                  total={total}
                  rowsPerPage={rowsPerPage}
                  data={content}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                  getDataHandler={getContent}
                  optionActions={(data) => (
                    <Options
                      data={data}
                      setAlertType={updateAlertType}
                      onComplete={() => {
                        openAlertModal();
                        handleAddContent();
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>
        <AlertModal
          open={isAlertOpen}
          onClose={closeAlertModal}
          title={MODAL_DATA[alertType].title}
          description={MODAL_DATA[alertType].description}
          ctaText="Close"
        />
      </LoggedIn>
    </>
  );
};

export default ContentManagement;
