module.exports = [
  {
    id: 0,
    value: "00:00 - 01:00",
    label: "00:00 - 01:00",
  },
  {
    id: 1,
    value: "01:00 - 02:00",
    label: "01:00 - 02:00",
  },
  {
    id: 2,
    value: "02:00 - 03:00",
    label: "02:00 - 03:00",
  },
  {
    id: 3,
    value: "03:00 - 04:00",
    label: "03:00 - 04:00",
  },
  {
    id: 4,
    value: "04:00 - 05:00",
    label: "04:00 - 05:00",
  },
  {
    id: 5,
    value: "05:00 - 06:00",
    label: "05:00 - 06:00",
  },
  {
    id: 6,
    value: "06:00 - 07:00",
    label: "06:00 - 07:00",
  },
  {
    id: 7,
    value: "07:00 - 08:00",
    label: "07:00 - 08:00",
  },
  {
    id: 8,
    value: "08:00 - 09:00",
    label: "08:00 - 09:00",
  },
  {
    id: 9,
    value: "09:00 - 10:00",
    label: "09:00 - 10:00",
  },
  {
    id: 10,
    value: "10:00 - 11:00",
    label: "10:00 - 11:00",
  },
  {
    id: 11,
    value: "11:00 - 12:00",
    label: "11:00 - 12:00",
  },
  {
    id: 12,
    value: "12:00 - 13:00",
    label: "12:00 - 13:00",
  },
  {
    id: 13,
    value: "13:00 - 14:00",
    label: "13:00 - 14:00",
  },
  {
    id: 14,
    value: "14:00 - 15:00",
    label: "14:00 - 15:00",
  },
  {
    id: 15,
    value: "15:00 - 16:00",
    label: "15:00 - 16:00",
  },
  {
    id: 16,
    value: "16:00 - 17:00",
    label: "16:00 - 17:00",
  },
  {
    id: 17,
    value: "17:00 - 18:00",
    label: "17:00 - 18:00",
  },
  {
    id: 18,
    value: "18:00 - 19:00",
    label: "18:00 - 19:00",
  },
  {
    id: 19,
    value: "19:00 - 20:00",
    label: "19:00 - 20:00",
  },
  {
    id: 20,
    value: "20:00 - 21:00",
    label: "20:00 - 21:00",
  },
  {
    id: 21,
    value: "21:00 - 22:00",
    label: "21:00 - 22:00",
  },
  {
    id: 22,
    value: "22:00 - 23:00",
    label: "22:00 - 23:00",
  },
  {
    id: 23,
    value: "23:00 - 00:00",
    label: "23:00 - 00:00",
  },
];
