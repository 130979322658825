import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "title") {
      return reducers.createFormErrorsHandler(
        "title",
        errorData.message,
        errorDispatch
      );
    }

    if (errorData.field === "sessionNumber") {
      return reducers.createFormErrorsHandler(
        "sessionNumber",
        errorData.message,
        errorDispatch
      );
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to save this content.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
