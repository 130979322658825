import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import appointmentsService from "services/appointments/appointments-service";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Button from "components/core/buttons/Primary/Primary";
import Checkbox from "components/core/form/Checkbox/Checkbox";
import Loader from "components/core/loaders/circular/Circular";
import styles from "./ViewAppointment.styles";
import stylesConfig from "theme/config";

const ViewAppointmentForm = ({ id, closeModal, onComplete, onCancel }) => {
  const [isLoading, updateIsLoading] = useState(false);
  const [appointment, updateaAppointment] = useState({ attendees: [] });
  const [checked, updateChecked] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState(undefined);

  const getAppointment = useCallback(
    async (id) => {
      updateIsLoading(true);

      try {
        const response = await appointmentsService.getAppointmentById(id);

        const checked = [];
        updateChecked(
          // eslint-disable-next-line array-callback-return
          response.attendees.map((item) => {
            if (item.attended) {
              checked.push(item.id);
            }
          })
        );

        updateaAppointment(response);
        updateChecked(checked);
        updateIsLoading(false);
      } catch (err) {
        updateIsLoading(false);
        setNoResultsMessage(
          "Unable to load patient details. Please try again later."
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getAppointment(id);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let loadingStyles = {};
  if (isLoading) {
    loadingStyles = styles.loading;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await appointmentsService.updateAppointmentAttendees(id, checked);

      updateIsLoading(false);
      onComplete();
    } catch (err) {
      updateIsLoading(false);
      setNoResultsMessage(
        "Unable to load patient details. Please try again later."
      );
    }
  };

  const onSelectHandler = (e, id) => {
    const value = e.target.checked;

    if (value) {
      checked.push(id);
    } else {
      const index = checked.indexOf(id.toString());
      if (index > -1) {
        checked.splice(index, 1);
      }
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Box style={loadingStyles}>
          <Loader />
        </Box>
      ) : noResultsMessage ? (
        <Box style={styles.container}>
          <Paragraph>{noResultsMessage}</Paragraph>
        </Box>
      ) : (
        <form style={styles.formFooter} onSubmit={onSubmit}>
          <Box style={styles.headerContainer}>
            <Box sx={styles.header}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Paragraph sx={styles.label}>Date:</Paragraph>
                <Paragraph sx={styles.noMargin}>{appointment.date}</Paragraph>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Paragraph sx={styles.label}>Time:</Paragraph>
                <Paragraph sx={styles.noMargin}>{appointment.time}</Paragraph>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Paragraph sx={styles.label}>Sesion Number:</Paragraph>
                <Paragraph sx={styles.noMargin}>
                  {appointment.sessionNumber}
                </Paragraph>
              </Box>
            </Box>
          </Box>
          <Box>
            <Paragraph
              sx={{
                ...styles.label,
                paddingBottom: stylesConfig.spacing.small,
              }}
            >
              Attendees
            </Paragraph>
            <Paragraph>Please mark those were present</Paragraph>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {appointment.attendees.map((attendee, index) => {
                return (
                  <>
                    <Checkbox
                      id={attendee.id}
                      label={attendee.name}
                      defaultChecked={attendee.attended}
                      onChange={(e) => onSelectHandler(e, attendee.id)}
                    />
                    {index < appointment.attendees.length - 1 && (
                      <div style={styles.divider} />
                    )}
                  </>
                );
              })}
            </Box>
            <Box style={styles.footer}>
              <Button
                type="submit"
                text="Save"
                variant="contained"
                style={styles.button}
              />
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
};
ViewAppointmentForm.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
ViewAppointmentForm.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};
export default ViewAppointmentForm;
