import React, { useState, useReducer, useEffect, useCallback } from "react";
import { Grid, Box } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HTTPError from "lib/errors/http-error";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Loader from "components/core/loaders/circular/Circular";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import UploadFileForm from "components/forms/upload-file/UploadFile";
import BasicFileList from "components/core/dropzone/drop-file-zone/file-list/BasicFileList";
import contentService from "services/content/content-service";
import SESSION_NUMBERS from "lib/const/appoitmentSessions";
import errorHandler from "./EditContent.error.handler";
import styles from "./EditContent.styles";
import stylesConfig from "theme/config";
import utils from "lib/utils";
import PropTypes from "prop-types";

const intialErrorState = {
  title: null,
  sessionNumber: null,
  general: null,
};

const formDefaultValues = {
  title: null,
  sessionNumber: null,
  file: null,
};

const EditContent = ({ onCancel, actions, closeModal, onComplete, id }) => {
  const [isLoading, updateIsLoading] = useState(false);
  const [isSavingContent, updateIsSavingContent] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState(undefined);
  const [isFromValid, updateIsFromValid] = useState(false);
  const [files, updateFiles] = useState("");
  const [form, updateForm] = useState({
    title: "",
    sessionNumber: 0,
    files: [],
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const handleCloseModal = () => {
    closeModal();
    onCancel();
  };

  const actionsWithProps = utils.mapPropsToChilden(actions, {
    onClick: handleCloseModal,
    isLoading,
  });

  const characterLimit = 246;

  const getcontentById = useCallback(
    async (id) => {
      updateIsLoading(true);

      try {
        const response = await contentService.getContentById(id);

        // Save initial values to use on modal close
        formDefaultValues.title = response.title;
        formDefaultValues.sessionNumber = response.sessionNumber;
        formDefaultValues.file = response.file;

        const newForm = {
          title: response.title,
          sessionNumber: response.sessionNumber,
          files: [
            {
              alt: response.fileName,
            },
          ],
        };

        updateForm(newForm);
        updateIsLoading(false);
      } catch (err) {
        updateIsLoading(false);
        setNoResultsMessage(
          "Unable to load content details. Please try again later."
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getcontentById(id);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isFormValid(form);

    const newForm = { ...form };
    newForm.files = [];
    updateForm(newForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const removeFile = () => {
    const newForm = { ...form };
    newForm.files = [];

    isFormValid(newForm);
    updateForm(newForm);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { title, sessionNumber } = form;
    let config = {};

    const formData = new FormData();
    formData.append("title", title);
    formData.append("sessionNumber", sessionNumber);

    if (files.length > 0) {
      formData.append("files", files[0]);

      config.headers = {
        "content-type": "multipart/form-data",
      };
    }

    try {
      updateIsSavingContent(true);
      await contentService.updateContent(formData, id, config);
      updateIsSavingContent(false);
      closeModal();
      await onComplete();
    } catch (err) {
      updateIsSavingContent(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const isFormValid = (form) => {
    const { title, sessionNumber } = form;
    if (title && sessionNumber && (files.length > 0 || form.files.length > 0)) {
      updateIsFromValid(true);
    } else {
      updateIsFromValid(false);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    isFormValid(newForm);
    updateForm(newForm);
  };

  let loadingStyles = {};
  if (isLoading) {
    loadingStyles = styles.loading;
  }

  return (
    <Box sx={styles.panel}>
      <>
        <Box sx={styles.header}>
          <HeadingBold text="Edit Content" />
          {actionsWithProps}
          {/* <CloseIcon onClick={() => closeModal()} style={styles.closeButton} /> */}
        </Box>
        <div style={styles.divider} />
      </>
      {isLoading ? (
        <Box style={loadingStyles}>
          <Loader />
        </Box>
      ) : noResultsMessage ? (
        <Box style={styles.container}>
          <Paragraph>{noResultsMessage}</Paragraph>
        </Box>
      ) : (
        <form onSubmit={onSubmit} style={styles.formFooter}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={styles.gridRow}>
              <InputField
                id="title"
                name="title"
                cy="title-field"
                label="Title"
                required
                defaultValue={form.title}
                theme="inputWhite"
                sx={styles.textFieldMargin}
                placeholder="Title"
                error={errorState.date ? true : false}
                helperText={errorState.date}
                onChange={(e) => handleOnFormChange(e)}
                inputProps={{
                  inputProps: {
                    maxLength: characterLimit,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={7} style={styles.gridRow}>
              <ReferenceDataSelect
                disabled={false}
                label="Add to Session"
                subLabel="Select the session you want to add this content to."
                id="sessionNumber"
                theme="inputWhite"
                value={form.sessionNumber}
                placeholder="Select a session"
                service={() => SESSION_NUMBERS}
                helperText={errorState.sessionNumber}
                error={errorState.sessionNumber ? true : false}
                sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
                onChange={(e) => handleOnFormChange(e)}
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <UploadFileForm saveFiles={updateFiles} />
              {files.length === 0 && form.files.length > 0 ? (
                <BasicFileList items={form.files} onClick={removeFile} />
              ) : null}
            </Grid>
          </Grid>

          {errorState.general && <ErrorHelperText text={errorState.general} />}

          <Box style={styles.footer}>
            <Button
              type="submit"
              text="Save"
              variant="contained"
              style={styles.button}
              loading={isSavingContent}
              disabled={!isFromValid}
            />
          </Box>
        </form>
      )}
    </Box>
  );
};

EditContent.defaultProps = {
  actions: undefined,
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
EditContent.propTypes = {
  actions: PropTypes.element,
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};

export default EditContent;
